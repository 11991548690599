<template v-if="results.length > 0 && company_icons.length <= 6 && badge.length > 0 && icon.length > 0">
  <section class="results">
    <div class="results__container container container__mini">
      <div class="results__top">
        <v-lazy-image
          :src="icon.url"
          rel="preload"
          width="151"
          height="151"
          alt="results"
        />
        <p class="results__top-text">
          {{results.description}}
        </p>
      </div>

      <h2 class="results__title">
        {{results.title}}
      </h2>

      <div class="results__wrap">
        <div class="results__item">
          <v-lazy-image
            v-if="badges[0]"
            :src="badges[0].url"
            rel="preload"
            width="63"
            height="72"
            alt="results"
          />

          <div class="results__item-wrap" v-if="results">
            <div class="results__item-title">
              {{results.text_block_0}}
            </div>

            <router-link tag="a" v-if="results.url_block_0" :to="{path: results.url_block_0}" class="results__item-link">
              {{ t('more_details') }}
            </router-link>

          </div>
        </div>

        <div class="results__item">
          <v-lazy-image
            v-if="badges[1]"
            :src="badges[1].url"
            rel="preload"
            width="63"
            height="72"
            alt="results"
          />

          <div class="results__item-wrap">
            <div class="results__item-title">
              {{results.text_block_1}}
            </div>

            <router-link tag="a" v-if="results.url_block_1" :to="{path: results.url_block_1}" class="results__item-link">
              {{ t('more_details') }}
            </router-link>

          </div>
        </div>

        <div class="results__item">
          <v-lazy-image
            v-if="badges[2]"
            :src="badges[2].url"
            rel="preload"
            width="63"
            height="72"
            alt="results"
          />

          <div class="results__item-wrap">
            <div class="results__item-title">
              {{results.text_block_2}}
            </div>

            <router-link tag="a" v-if="results.url_block_2" :to="{path: results.url_block_2}" class="results__item-link">
              {{ t('more_details') }}
            </router-link>

          </div>
        </div>
      </div>

      <div class="results__company">
        <v-lazy-image
          v-for="com_icon in company_icons" :key="com_icon.id"
          :src="com_icon.url"
          rel="preload"
          width="114"
          height="35"
          :alt="com_icon.alt"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import VLazyImage from "v-lazy-image";

export default defineComponent ({
  components: {
    VLazyImage
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
    results: {
      type: Array,
      required: true
    },
    company_icons: {
      type: Array,
      required: true
    },
    badges: {
        type: Array,
        required: true
    },
    icon: {
      type: Array,
      required: true
    }
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.results {
  @include property("margin-top", 185, 40);
  @include property("margin-bottom", 70, 25);
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__top {
    display: flex;
    align-items: center;
    @include property("margin-bottom", 110, 30);

    @media (max-width: 575.98px) {
      flex-direction: column;
    }

    img {
      display: block;
      width: 151px;
      height: 151px;
      flex: 0 0 auto;
      margin-right: 50px;

      @media (max-width: 575.98px) {
        width: 100px;
        height: 100px;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    &-text {
      @include property("font-size", 24, 21, true, 768, 320);
      line-height: calc(40 / 24);
      color: #f9b80e;
      max-width: 365px;
      margin: 0;
    }
  }

  &__title {
    font-weight: 700;
    @include property("font-size", 24, 21, true, 768, 320);
    line-height: calc(31 / 24);
    letter-spacing: 0.48px;
    color: #f9b80e;
    text-transform: uppercase;
    @include property("margin-bottom", 60, 20);
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -3px;
    justify-content: center;
    width: 100%;
  }

  &__item {
    display: flex;
    width: 100%;
    min-width: 290px;
    margin: 0 3px 15px;
    padding-top: 30px;
    padding-bottom: 30px;
    @include property("padding-left", 48, 15, true, 768, 320);
    @include property("padding-right", 48, 15, true, 768, 320);
    border: 1px solid #f9b80e;
    border-radius: 5px;
    transition: border 0.3s linear;

    @media (min-width: 576px) {
      min-width: 400px;
      width: calc(33.333% - 6px);
    }

    @media (hover: hover) {
      border-color: transparent;
      &:hover {
        border-color: #f9b80e;
      }
    }

    img {
      display: block;
      flex: 0 0 auto;
      width: 63px;
      height: 72px;
      object-fit: contain;
      object-position: top;

      @media (max-width: 575.98px) {
        width: 50px;
        height: 59px;
      }
    }

    &-wrap {
      @include property("padding-left", 32, 15, true, 768, 320);
      @include property("margin-left", 32, 15, true, 768, 320);
      border-left: 3px solid #f9b80e;
    }

    &-title {
      @include property("font-size", 24, 21, true, 768, 320);
      line-height: calc(30 / 24);
      color: #f9b80e;
      margin-bottom: 1rem;
    }

    &-link {
      font-size: 16px;
      line-height: calc(30 / 16);
      letter-spacing: 2.4px;
      color: #f9b80e;
      margin-top: auto;
    }
  }

  &__company {
    display: grid;
    @include property("grid-gap", 80, 30, true, 1670, 576);

    @media (min-width: 1024px) {
      grid-template-columns: repeat(6, max-content);
    }

    @media (min-width: 576px) and (max-width: 1023.98px) {
      grid-template-columns: repeat(3, max-content);
    }

    @media (min-width: 430px) and (max-width: 575.98px) {
      grid-template-columns: repeat(2, max-content);
    }

    img {
      display: block;
      width: max-content;
      flex: 0 0 auto;
      margin: 0 auto;
    }
  }
}
</style>
